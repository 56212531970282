/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "pt";
export const localeOptions = [
  // { id: "en", name: "English - LTR", direction: "ltr" },
  // { id: "es", name: "Español", direction: "ltr" },
  // { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const searchPath = "/app/pages/search";
export const apiPath = "https://api.moboffice.com.br/mob/api/v1/backoffice";
// export const apiPath = "http://localhost:8080/mob/api/v1/backoffice";

//FIXME verificar onde isso é usado
export const servicePath = "https://api.coloredstrategies.com";

export const themeColorStorageKey="__theme_color"
export const isMultiColorActive = false;
export const isDarkSwitchActive = true;
export const defaultColor = "light.blue";
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;